.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #1f2937;
  padding: 2rem;
  border-radius: 0.5rem;
  max-width: 32rem;
  width: 100%;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  color: white;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker {
  font-family: inherit;
  border: none;
  border-radius: 0.5rem;
  overflow: hidden;
}

.react-datepicker__header {
  background-color: #374151;
  border-bottom: none;
  color: white;
}

.react-datepicker__current-month,
.react-datepicker__day-name {
  color: white;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  background-color: #3b82f6;
  color: white;
}

.react-datepicker__day:hover {
  background-color: #2563eb;
}
